import { IMAGES } from '@assets';
import { mapState } from 'vuex';
import {
  MainVisualContent,
  AgreementContent,
  DetailContent,
  AccordionContent,
} from '@components';
import {
  CAMPAIGN_SERIAL_TYPE,
  CAMPAIGN_PHONE_NUMBER_TYPE,
} from '@constants';

export default {
  name: 'HomeTopPage',
  components: {
    MainVisualContent,
    AgreementContent,
    DetailContent,
    AccordionContent,
  },
  data() {
    return {
      openAccordionState: 0,
      isProcessing: false,
      defaultImageUrl: IMAGES.main_visual.top
    };
  },
  computed: {
    ...mapState({
      topContentSetting: 'topContentSetting',
      campaignInfo: 'campaignInfo',
      campaignSetting: 'campaignSetting',
    }),
  },
  methods: {
    // Get header logo url
    fullMainVisualUrl() {
      return this.topContentSetting?.main_visual_image_url || this.defaultImageUrl;
    },
    // Set Open/Close state a collapse in a accordion
    openAccordion(value) {
      // Update open state of accordion
      this.openAccordionState = value;
    },
    handleRedirectToEntryPage(campaignType) {
      // Set agreement top term state to true
      this.$store.commit('setTopPageAgreementTermState', true);

      // Handle redirect to entry page
      switch (campaignType) {
        case CAMPAIGN_SERIAL_TYPE: // Campaign type is serial code
          this.isProcessing = true;

          return this.$router.push(
            this.$helpers.redirectWithUtmQuery('campaign.auth.serial', {
              code: this.$route?.query?.code,
            })
          );
        case CAMPAIGN_PHONE_NUMBER_TYPE: // Campaign type is phone number
          this.isProcessing = true;

          return this.$router.push(
            this.$helpers.redirectWithUtmQuery('campaign.auth.verifyotp')
          );
        default:
          return this.$router.push({
            name: 'campaign.error.404',
          });
      }
    },
    handleEntry() {
      this.handleRedirectToEntryPage(this.campaignInfo?.campaign_type).catch(() => {});
    },
  },
  created() {
    // Set agreement top term state to false
    this.$store.commit('setTopPageAgreementTermState', false);

    // Check if have skip top will redirect to entry page
    if (this.campaignSetting?.skip_top_flg) {
      // Check if the campaign is expired
      if (this.campaignInfo?.is_expired) {
        this.$store.dispatch('stopPageLoading');
      } else {
        this.handleRedirectToEntryPage(this.campaignInfo?.campaign_type)
          .finally(() => {
            // Stop page loading
            this.$store.dispatch('stopPageLoading');
          });
      }
    }
  },
};
